var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": true,
      "width": _vm.width,
      "persistent": _vm.persistent
    },
    on: {
      "input": _vm.dismiss
    }
  }, [_c('v-card', [_vm.title ? [_c('v-card-title', {
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _c('v-divider')] : _vm._e(), _vm.msg ? [_c('v-card-text', {
    staticClass: "pt-4 break-word",
    domProps: {
      "innerHTML": _vm._s(_vm.msg)
    }
  }), _c('v-divider')] : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _vm._l(_vm.buttons, function (btn) {
    return _c('v-btn', {
      key: btn,
      attrs: {
        "text": "",
        "loading": !!_vm.loadTimeout,
        "color": btn == _vm.primary ? 'primary' : null
      },
      on: {
        "click": function ($event) {
          return _vm.resolve(btn);
        }
      }
    }, [_vm._v(" " + _vm._s(btn) + " ")]);
  })], 2)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }