var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.frame ? _c('div', {
    staticClass: "absolute-fill frame",
    class: [_vm.frame, _vm.type]
  }, [_c('div', {
    staticClass: "absolute-fill background",
    domProps: {
      "innerHTML": _vm._s(_vm.svgBackground)
    }
  }), _c('div', {
    staticClass: "absolute-fill blob",
    class: {
      'overflow-hidden': _vm.hasCrop
    },
    style: {
      'mask-image': _vm.mask
    }
  }, [_vm._t("default")], 2), _vm.svgForeground ? _c('div', {
    staticClass: "absolute-fill foreground",
    domProps: {
      "innerHTML": _vm._s(_vm.svgForeground)
    }
  }) : _c('div', {
    staticClass: "absolute-fill foreground"
  }, [_c('div', {
    staticClass: "edge left"
  }), _c('div', {
    staticClass: "edge right"
  }), _c('div', {
    staticClass: "edge top"
  }), _c('div', {
    staticClass: "edge bottom"
  }), _c('div', {
    staticClass: "corner top-left"
  }), _c('div', {
    staticClass: "corner top-right"
  }), _c('div', {
    staticClass: "corner bottom-left"
  }), _c('div', {
    staticClass: "corner bottom-right"
  })])]) : _c('div', {
    staticClass: "absolute-fill blob",
    class: {
      'overflow-hidden': _vm.hasCrop
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }