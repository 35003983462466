var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Video maker",
      "title": "Make a video with a group",
      "subtitle": "Instant results, no video editing skills required",
      "action": "View all video themes",
      "icon": _vm.mdiVideoVintage,
      "to": {
        name: 'catalog-video'
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "root-grid"
  }, [_c('div', [_c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "li-pop font-weight-bold ec-dark-blue--text",
    attrs: {
      "exact": "",
      "to": {
        name: 'catalog-video'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiVideoVintage)
    }
  }), _c('div', [_vm._v("All video themes")]), _c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1)], 1), _c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.CATEGORIES, function (_ref) {
    var name = _ref[0],
      icon = _ref[1];
    return _c('v-list-item', {
      key: name,
      staticClass: "li-pop ec-dark-blue--text",
      attrs: {
        "exact": "",
        "to": {
          name: 'catalog-video',
          query: {
            q: name
          }
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _c('div', {
      domProps: {
        "textContent": _vm._s(name)
      }
    }), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1);
  }), 1)], 1), _c('div', [_c('div', {
    staticClass: "pt-3 px-3 caption"
  }, [_vm._v("Popular video themes")]), _c('v-list', {
    staticClass: "transparent",
    staticStyle: {
      "width": "320px",
      "min-width": "100%"
    },
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.THEMES, function (_ref2) {
    var templateId = _ref2[0],
      q = _ref2[1],
      name = _ref2[2],
      poster = _ref2[3];
    return _c('v-list-item', {
      key: templateId,
      staticClass: "li-pop ec-dark-blue--text",
      attrs: {
        "exact": "",
        "to": {
          name: 'catalog-video',
          params: {
            templateId,
            query: q
          }
        }
      }
    }, [_c('v-img', {
      staticClass: "v-card v-sheet ml-n1 mr-3",
      attrs: {
        "width": "72",
        "max-width": "72",
        "aspect-ratio": 16 / 9,
        "src": `https://cdn.ellacard.com/group-video-template/${templateId}/${poster}`
      }
    }), _c('div', {
      staticClass: "py-3"
    }, [_c('div', [_vm._v(_vm._s(name))]), _c('div', {
      staticClass: "caption"
    }, [_vm._v("Make this video")])]), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1);
  }), 1)], 1)]), _c('v-divider'), _c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "li-pop font-weight-bold ec-dark-blue--text",
    attrs: {
      "to": {
        name: 'start-video-maker'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiInformation)
    }
  }), _c('div', [_vm._v("Learn more about our video maker")]), _c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1)], 1), _c('router-link', {
    attrs: {
      "to": {
        name: 'start-video-maker'
      }
    }
  }, [_c('outline-laptop', {
    staticClass: "mt-3 mx-auto mb-6 hover-pop",
    staticStyle: {
      "max-width": "400px"
    }
  }, [_c('v-lazy', [_c('video', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "width": "100%",
      "playsinline": "",
      "autoplay": "",
      "muted": "",
      "loop": "",
      "src": "https://cdn.ellacard.com/start/group_video_header_720.mp4",
      "srcset": "\n            https://cdn.ellacard.com/start/group_video_header_720.mp4 720w,\n            https://cdn.ellacard.com/start/group_video_header_640.mp4 640w,\n            https://cdn.ellacard.com/start/group_video_header_480.mp4 480w,\n            https://cdn.ellacard.com/start/group_video_header_320.mp4 320w\n          "
    },
    domProps: {
      "muted": true
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'catalog-video',
          hash: '#intro'
        });
      }
    }
  })])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }