<template>
  <nav-menu name="Start" v-bind="$attrs" v-on="$listeners">
    <v-list class="transparent" dense>
      <v-list-item
        v-for="[ to, title, icon ], i in ACTIONS" :key="title"
        class="li-pop ec-dark-blue--text"
        v-bind:class="{ 'mb-6': i == 2 || i == 7, 'font-weight-bold': i < 3 }"
        :to="to"
      >
        <v-icon small v-html="icon" />
        <div v-html="title" />
        <v-icon small v-html="mdiChevronRight" />
      </v-list-item>
    </v-list>
  </nav-menu>
</template>


<script>
import NavMenu from '@/components/NavMenu.vue';
import { ACTIONS } from '@/utils/start-actions.js';
import { mdiChevronRight } from '@mdi/js';

export default {
  name: 'nav-menu-start',

  components: {
    NavMenu
  },

  created() {
    Object.assign(this, { ACTIONS, mdiChevronRight });
  }
}
</script>
