<template>
  <nav-menu
    name="Video maker"
    title="Make a video with a group"
    subtitle="Instant results, no video editing skills required"
    action="View all video themes"
    :icon="mdiVideoVintage"
    :to="{ name: 'catalog-video' }"
    v-bind="$attrs" v-on="$listeners"
  >
    <div class="root-grid">
      <div>
        <v-list class="transparent" dense>
          <v-list-item class="li-pop font-weight-bold ec-dark-blue--text" exact :to="{ name: 'catalog-video' }">
            <v-icon small v-html="mdiVideoVintage" />
            <div>All video themes</div>
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>
        </v-list>
        <v-list class="transparent" dense>
          <v-list-item
            v-for="[ name, icon ] in CATEGORIES" :key="name"
            class="li-pop ec-dark-blue--text"
            exact
            :to="{ name: 'catalog-video', query: { q: name } }"
          >
            <v-icon small v-html="icon" />
            <div v-text="name" />
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>
        </v-list>
      </div>

      <div>
        <div class="pt-3 px-3 caption">Popular video themes</div>
        <v-list class="transparent" style="width: 320px; min-width: 100%;" dense>
          <v-list-item
            v-for="[ templateId, q, name, poster ] in THEMES" :key="templateId"
            class="li-pop ec-dark-blue--text"
            exact
            :to="{ name: 'catalog-video', params: { templateId, query: q } }"
          >
            <v-img
              class="v-card v-sheet ml-n1 mr-3"
              width="72" max-width="72"
              :aspect-ratio="16 / 9"
              :src="`https://cdn.ellacard.com/group-video-template/${templateId}/${poster}`"
            />
            <div class="py-3">
              <div>{{name}}</div>
              <div class="caption">Make this video</div>
            </div>
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>
        </v-list>
      </div>

    </div>

    <v-divider />

    <v-list class="transparent" dense>
      <v-list-item
        class="li-pop font-weight-bold ec-dark-blue--text"
        :to="{ name: 'start-video-maker' }"
      >
        <v-icon small v-html="mdiInformation" />
        <div>Learn more about our video maker</div>
        <v-icon small v-html="mdiChevronRight" />
      </v-list-item>
    </v-list>

    <router-link :to="{ name: 'start-video-maker' }">
      <outline-laptop class="mt-3 mx-auto mb-6 hover-pop" style="max-width: 400px;">
        <v-lazy>
          <video
            style="cursor: pointer;"
            width="100%"
            playsinline autoplay muted loop
            src="https://cdn.ellacard.com/start/group_video_header_720.mp4"
            srcset="
              https://cdn.ellacard.com/start/group_video_header_720.mp4 720w,
              https://cdn.ellacard.com/start/group_video_header_640.mp4 640w,
              https://cdn.ellacard.com/start/group_video_header_480.mp4 480w,
              https://cdn.ellacard.com/start/group_video_header_320.mp4 320w
            "
            @click="$router.push({ name: 'catalog-video', hash: '#intro' })"
          />
        </v-lazy>
      </outline-laptop>
    </router-link>

  </nav-menu>
</template>


<style scoped>
.root-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.root-grid > *:first-child {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

@media (min-width: 600px) {
  .header .root-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .header .root-grid > *:first-child {
    border-bottom: none;
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
}

@media (min-width: 960px) {
  .root-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .root-grid > *:first-child {
    border-bottom: none;
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
}

.li-pop:hover > .v-image:nth-child(1) {
  transform: scale(1.2);
}

.hover-pop {
  cursor: pointer;
  transition: transform .12s, filter .12s;
}

.hover-pop:hover {
  transform: scale(1.03);
  filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0 1px 5px rgba(0, 0, 0, 0.12));
}
</style>


<script>
import NavMenu from '@/components/NavMenu.vue';
import OutlineLaptop from '@/components/OutlineLaptop.vue';
import { mdiChevronRight, mdiVideoVintage, mdiImageMultiple, mdiCakeVariant, mdiCandle, mdiBriefcase, mdiHeart, mdiSchool, mdiInformation } from '@mdi/js';

const CATEGORIES = [
  [ 'Slideshow', mdiImageMultiple ],
  [ 'Birthday', mdiCakeVariant ],
  [ 'Memorial', mdiCandle ],
  [ 'Retirement', mdiBriefcase ],
  [ 'Romantic', mdiHeart ],
  [ 'Graduation', mdiSchool ]
];

const THEMES = [
  [ 'c02e39f8-92ab-42de-8cb9-71bf0bce37b5', 'Birthday', 'Birthday stand-up', 'poster_6cafbc3d-9a94-40da-9998-eb1b318d51f1.jpg' ],
  [ '5f1223b7-deb4-4fd2-ab87-334ee8850dc7', 'Wedding', 'Bridal shower', 'poster_faff2a97-96ed-4060-9844-ccf43bdbb229.jpg' ],
  [ '49a43bad-9ecf-423f-8929-0b8ff6532745', 'Graduation', 'Graduation cheers', 'poster_f87c459b-c259-4361-b3e5-67ed55edd3b7.jpg' ],
  [ '9ffd2882-d92f-4eb5-9f2f-5f302d73c86c', 'Work', 'Retirement party', 'poster_9b517036-d37b-4db0-98dc-43eace3503d5.jpg' ]
];

export default {
  name: 'nav-menu-group-videos',

  components: {
    NavMenu,
    OutlineLaptop
  },

  created() {
    Object.assign(this, { THEMES, CATEGORIES, mdiChevronRight, mdiVideoVintage, mdiInformation });
  }
}
</script>
