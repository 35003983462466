// Force the site to reload (upgrade to the latest version) if the user has been inactive for too long
window.lastActivity = new Date();
window.addEventListener('focus', () => (new Date() - window.lastActivity > 24 * 60 * 60 * 1000) && location.reload(true));
window.addEventListener('blur', () => window.lastActivity = new Date());

// Set up Google analytics with the 'gtag' function
window.dataLayer = window.dataLayer || [];
window.gtag = function() {
  dataLayer.push(arguments);
}

// Set up Microsoft analytics
window.uetq = window.uetq || [];

// Consent enabled by default to restore functionality for Google analytics (see also the 'global.cookies' watcher in
// App.vue)
// gtag('consent', 'default', { 'ad_storage': 'denied', 'analytics_storage': 'denied' });

gtag('js', new Date());
gtag('config', process.env.OP_DEV_CONFIG['google-analytics-id'], { send_page_view: false });
gtag('config', process.env.OP_DEV_CONFIG['google-ads-id'], { allow_enhanced_conversions: true });


// Globals
import 'lodash';

class VisibleError extends Error {
  constructor(msg, type='warning', showContactUs=false) {
    // 'type' should be one of 'error' or 'warning'
    super(msg);
    this.type = type;
    this.showContactUs = showContactUs;
  }
}
window.VisibleError = VisibleError;

// WARNING: you ran into issues were these properties were not immediately available everywhere, such as the
// /utils/local-storage.js file when checking the former IS_IFRAME property
window.IS_MOBILE = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
window.IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

// Set this flag to exclude gift cards from the site (save for the button in the card-editor)
window.XGC = false;

// TODO: implement some of the stuff from here: https://v2.vuejs.org/v2/guide/components-dynamic-async.html

import Vue from 'vue';
import router from '@/router';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import ErrorDialog from '@/components/ErrorDialog.vue';
import Snackbar from '@/components/Snackbar.vue';
import TooltipBtn from '@/components/TooltipBtn.vue';
import category from '@/filters/category.js';
import countryName from '@/filters/country-name.js';
import moment from '@/filters/moment.js';
import email from '@/filters/email.js';
import phone from '@/filters/phone.js';
import videoTimestamp from '@/filters/video-timestamp.js';
import usd from '@/filters/usd.js';
import asyncStatus from '@/mixins/async-status.js';
import confirm from '@/mixins/confirm.js';
import copyToClipboard from '@/mixins/copy-to-clipboard.js';
import globalStorage from '@/mixins/global-storage.js';
import notify from '@/mixins/notify.js';
import opDevCall from '@/mixins/op-dev-call.js';
import routeAssign from '@/mixins/route-assign.js';
import '@/patches/draw-image.js';
import EventTracker from '@/utils/event-tracker.js';
import loadScript from '@/utils/load-script.js';
import '@/styles/app.scss';

Vue.component('ErrorDialog', ErrorDialog);
Vue.component('Snackbar', Snackbar);
Vue.component('TooltipBtn', TooltipBtn);

Vue.filter('category', category);
Vue.filter('country-name', countryName);
Vue.filter('moment', moment);
Vue.filter('email', email);
Vue.filter('phone', phone);
Vue.filter('video-timestamp', videoTimestamp);
Vue.filter('usd', usd);

Vue.mixin(asyncStatus);
Vue.mixin(confirm);
Vue.mixin(copyToClipboard);
Vue.mixin(notify);
Vue.mixin(globalStorage);
Vue.mixin(opDevCall);
Vue.mixin(routeAssign);

// Global properties

// A better way to measure this: https://stackoverflow.com/a/52854585
Vue.prototype.IS_TOUCH = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);

Vue.prototype.IS_MOBILE = IS_MOBILE;
Vue.prototype.IS_IOS = IS_IOS;
Vue.prototype.IS_IFRAME = window !== window.parent;

Vue.config.errorHandler = (e, vm, info) => {
  // Captures errors that are specific to vue instances
  console.error(e);
  $event.log('err-vue', { component: vm.$options.name, info, stack: e?.stack || e });
};

addEventListener('error', e => $event.log('err-window', { stack: e.error?.stack || e.message }));

// All of the following external resources can be loaded asynchronously and should not block the initial render

window.loadRecaptchaPromise = new Promise(r => window.loadRecaptchaPromise_r = r);
window.loadStripePromise = new Promise(r => window.loadStripePromise_r = r);
window.loadPaypalPromise = new Promise(r => window.loadPaypalPromise_r = r);
window.loadDeferredScripts = () => {

  // https://developers.google.com/tag-platform/gtagjs/install
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.OP_DEV_CONFIG['google-analytics-id']}`);

  // https://ui.ads.microsoft.com/campaign/vnext/uettag
  // loadScript('https://bat.bing.com/bat.js').then(() => {
  //   window.uetq = new UET({ ti: '56344998', q: uetq });
  //   uetq.push('pageLoad');
  // });

  loadScript('https://www.google.com/recaptcha/api.js').then(loadRecaptchaPromise_r);

  // Always load stripe globally to enable better fruad detection
  // https://www.npmjs.com/package/@stripe/stripe-js
  loadScript('https://js.stripe.com/v3').then(() => loadStripePromise_r(Stripe(process.env.OP_DEV_CONFIG['stripe-api-key'])));

  // Load PayPal once globally to save time later
  // https://developer.paypal.com/docs/checkout/reference/customize-sdk/#query-parameters
  loadScript(`https://www.paypal.com/sdk/js?client-id=${process.env.OP_DEV_CONFIG['paypal-client-id']}&currency=USD`).then(loadPaypalPromise_r);

};


const vm = new Vue({ vuetify, router, render: h => h(App) }).$mount('#app');

window.$event = new EventTracker(vm);
