var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._b({
    directives: [{
      name: "no-scroll",
      rawName: "v-no-scroll"
    }],
    attrs: {
      "width": "500",
      "value": _vm.value
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }, 'v-dialog', _vm.$attrs, false), [_c('v-card', [_c('v-btn', {
    staticClass: "float-right mt-3 mr-3",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1), _c('v-card-title', [_vm._v("Create an Ellacard organization")]), _c('v-card-subtitle', [_vm._v("No payment required")]), _vm._t("default"), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('org-sign-up-form', {
    attrs: {
      "hide-email": _vm.hideEmail
    },
    on: {
      "cancel": function ($event) {
        return _vm.$emit('cancel');
      },
      "complete": function ($event) {
        return _vm.$emit('complete');
      }
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }