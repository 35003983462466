import iconPathCardGroup from 'raw-loader!@/assets/icon-path-card-group.txt';
import iconPathCardPersonal from 'raw-loader!@/assets/icon-path-card-personal.txt';
import iconPathCardInvitation from 'raw-loader!@/assets/icon-path-card-invitation.txt';
import { mdiVideoVintage, mdiWalletGiftcard, mdiDomain, mdiEmailMultiple, mdiCalendarStart, mdiPencilRuler } from '@mdi/js';


const ACTIONS = [
  [ { name: 'catalog-category', params: { category: 'greeting-cards', hideHeader: true } }, 'Send a card', iconPathCardPersonal ],
  [ { name: 'catalog-category', params: { category: 'group-cards', hideHeader: true } }, 'Send a group card', iconPathCardGroup ],
  [ { name: 'catalog-category', params: { category: 'invitations', hideHeader: true } }, 'Send an invitation', iconPathCardInvitation ],
  [ { name: 'catalog-gift-cards' }, 'Send a gift card', mdiWalletGiftcard ],
  [ { name: 'catalog-video' }, 'Send a group video', mdiVideoVintage ],
  [ { name: 'batch-order' }, 'Schedule automated cards', mdiCalendarStart ],
  [ { name: 'bulk-email' }, 'Send a mass-email', mdiEmailMultiple ],
  [ { name: 'design' }, 'Create a card design', mdiPencilRuler ],
  [ { name: 'account-org' }, 'Set up an organization', mdiDomain ]
];


export { ACTIONS };
