var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-blob mx-n4 my-n2 px-4 py-2 d-flex",
    class: `align-${_vm.blob.text.valign || 'center'}`,
    style: {
      opacity: _vm.loadFontStatus.pending || !_vm.didAutoScale ? 0 : 1
    }
  }, [_vm.showTemplateEditable ? _c('div', {
    staticClass: "absolute text-blob-fill template-editable"
  }) : _vm._e(), _c('div', {
    ref: "text",
    staticClass: "text-input group-video-text",
    class: {
      'user-select-auto': _vm.isSelected || _vm.mode == 'view'
    },
    style: _vm.blob.text.css || {},
    attrs: {
      "spellcheck": "false",
      "contenteditable": _vm.isEditable && (!_vm.blob.text.event_link || _vm.blob.text.event_link.disabled)
    },
    on: {
      "input": _vm.onInput,
      "focus": _vm.onFocusText,
      "blur": _vm.onBlurText,
      "paste": _vm.onPasteText,
      "keydown": function ($event) {
        $event.stopPropagation();
        return _vm.onKeyDown.apply(null, arguments);
      },
      "dragstart": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }