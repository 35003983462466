import { render, staticRenderFns } from "./NavMenuGroupVideos.vue?vue&type=template&id=69b20abc&scoped=true"
import script from "./NavMenuGroupVideos.vue?vue&type=script&lang=js"
export * from "./NavMenuGroupVideos.vue?vue&type=script&lang=js"
import style0 from "./NavMenuGroupVideos.vue?vue&type=style&index=0&id=69b20abc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69b20abc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VDivider,VIcon,VImg,VLazy,VList,VListItem})
