var render = function render(){
  var _vm$blob$text;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'pointer-events-none': !_vm.isEditable
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('blob-mask', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "mask": _vm.blob.mask,
      "blob-transform": _vm.blobTransform,
      "blob-mask-transform": _vm.blobMaskTransform,
      "blob-mask-relative-transform": _vm.blobMaskRelativeTransform
    }
  }, [_vm.showPrivacyScreen ? _c('div', {
    staticClass: "absolute-fill pointer-events-none d-flex align-center justify-center",
    staticStyle: {
      "z-index": "3"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "pointer-events": "auto"
    },
    attrs: {
      "x-large": "",
      "icon": "",
      "color": "rgba(0, 0, 0, 0.27)"
    },
    on: {
      "click": _vm.showPrivate
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiLock)
    }
  })], 1)], 1) : _vm._e(), _c('blob-frame', {
    class: {
      'pointer-events-none': !_vm.isEditable,
      'private-blob': _vm.showPrivacyScreen
    },
    style: _vm.blobFrameStyles,
    attrs: {
      "frame": _vm.opts.frame,
      "options": _vm.opts.frame_options,
      "type": _vm.blob.type,
      "has-crop": !!_vm.blob.crop
    },
    on: {
      "touchmove": function (e) {
        return !_vm.isEditable && e.preventDefault();
      }
    },
    nativeOn: {
      "mousedown": function ($event) {
        return _vm.move.apply(null, arguments);
      },
      "touchstart": function ($event) {
        return _vm.move.apply(null, arguments);
      }
    }
  }, [_vm.blob.type == 'TEXT' ? _c('text-blob', {
    ref: "text",
    attrs: {
      "blob": _vm.showPrivacyScreen ? Object.assign({}, _vm.blob, {
        text: Object.assign({}, _vm.blob.text, {
          font: 'Redacted Script'
        })
      }) : _vm.blob,
      "mode": _vm.mode,
      "is-editable": _vm.isEditable,
      "is-selected": _vm.isSelected,
      "is-transform-in-progress": !!_vm.transformInProgress,
      "virtual-keyboard": _vm.isShowingVirtualKeyboard
    },
    on: {
      "update:virtualKeyboard": function ($event) {
        _vm.isShowingVirtualKeyboard = $event;
      },
      "update:virtual-keyboard": function ($event) {
        _vm.isShowingVirtualKeyboard = $event;
      },
      "delete": function ($event) {
        return _vm.$emit('delete', true);
      },
      "loaded": function ($event) {
        return _vm.$emit('loaded');
      },
      "deselect": function ($event) {
        return _vm.$emit('deselect');
      },
      "click-text-event-link": function ($event) {
        return _vm.$emit('click-text-event-link');
      }
    }
  }) : _vm.blob.type == 'IMG' ? _c('image-blob', {
    attrs: {
      "blob": _vm.blob,
      "is-editable": _vm.isEditable,
      "is-preview": _vm.mode == 'preview',
      "is-selected": _vm.isSelected,
      "static": _vm.static
    },
    on: {
      "loaded": function ($event) {
        return _vm.$emit('loaded');
      }
    }
  }) : _vm.blob.type == 'VIDEO' ? _c('video-blob', {
    ref: "control-target",
    attrs: {
      "blob": _vm.blob,
      "is-preview": _vm.mode == 'preview',
      "is-editable": _vm.isEditable,
      "is-selected": _vm.isSelected,
      "is-page-active": _vm.isPageActive
    },
    on: {
      "play-video": function ($event) {
        return _vm.$emit('play-video');
      },
      "loaded": function ($event) {
        return _vm.$emit('loaded');
      }
    }
  }) : _vm.blob.type == 'GIFTCARD' ? _c('giftcard-blob', {
    attrs: {
      "blob": _vm.blob
    },
    on: {
      "loaded": function ($event) {
        return _vm.$emit('loaded');
      }
    }
  }) : _vm.blob.type == 'GROUP_VIDEO' ? _c('group-video-blob', {
    attrs: {
      "mode": _vm.mode,
      "blob": _vm.blob
    },
    on: {
      "loaded": function ($event) {
        return _vm.$emit('loaded');
      }
    }
  }) : _vm.blob.type == 'BTN' ? _c('btn-blob', {
    attrs: {
      "is-owner": _vm.isPrimary || _vm.isEditable,
      "blob": _vm.blob,
      "mode": _vm.mode
    },
    on: {
      "loaded": function ($event) {
        return _vm.$emit('loaded');
      },
      "click": function ($event) {
        return _vm.$emit('click-btn');
      }
    }
  }) : _vm.blob.type == 'GRADIENT' ? _c('gradient-blob', {
    attrs: {
      "blob": _vm.blob
    }
  }) : _vm._e()], 1)], 1), _vm.hasControls && !_vm.showPrivacyScreen && _vm.mode != 'design' ? _c('div', {
    staticClass: "absolute user-select-none",
    class: {
      'pointer-events-none': !_vm.isDraggingOnPage
    },
    staticStyle: {
      "z-index": "2"
    },
    style: _vm.blobMaskTransform || _vm.blobTransform,
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm.isEditable && (_vm$blob$text = _vm.blob.text) !== null && _vm$blob$text !== void 0 && _vm$blob$text.event_link ? _c('div', {
    staticClass: "absolute mx-n4 my-n2",
    staticStyle: {
      "pointer-events": "auto",
      "cursor": "pointer",
      "width": "calc(100% + 32px)",
      "height": "calc(100% + 16px)"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click-text-event-link');
      }
    }
  }) : _vm.isEditable && _vm.blob.template && (_vm.blob.type == 'IMG' || _vm.blob.type == 'VIDEO') && _vm.blob.mask.placeholder == _vm.opts.src ? _c('mask-blob-controls', {
    attrs: {
      "user-id": _vm.userId,
      "blob": _vm.blob,
      "selected": _vm.selected,
      "mode": _vm.mode,
      "is-page-active": _vm.isPageActive && !_vm.isPageReadonly,
      "is-dragging-on-page": _vm.isDraggingOnPage,
      "scale": _vm.scale
    },
    on: {
      "prompt-upload": function ($event) {
        _vm.select();
        _vm.$emit('prompt-upload');
      },
      "prompt-record-video": function ($event) {
        _vm.select();
        _vm.$emit('prompt-record-video');
      }
    }
  }) : _vm.blob.type == 'VIDEO' ? _c('video-blob-controls', {
    attrs: {
      "target": _vm.controlTarget,
      "is-editable": _vm.isEditable,
      "is-selected": _vm.isSelected
    },
    on: {
      "select": function ($event) {
        return _vm.$emit('select');
      }
    }
  }) : _vm.blob.type == 'GIFTCARD' ? _c('giftcard-blob-controls', {
    attrs: {
      "blob": _vm.blob,
      "skin": _vm.skin,
      "is-owner": _vm.isPrimary,
      "is-card-owner": _vm.isCardOwner,
      "is-verified-recipient": _vm.isVerifiedRecipient,
      "scale": _vm.scale,
      "mode": _vm.mode
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click-giftcard');
      }
    }
  }) : _vm.blob.type == 'GROUP_VIDEO' ? _c('group-video-blob-controls', {
    attrs: {
      "blob": _vm.blob,
      "selected": _vm.selected,
      "is-owner": _vm.isPrimary,
      "scale": _vm.scale,
      "mode": _vm.mode
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click-group-video');
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.isSelected && (!_vm.isDraggingOnPage || _vm.transformInProgress == 'move') ? _c('div', {
    staticClass: "absolute pointer-events-none user-select-none",
    staticStyle: {
      "z-index": "3"
    },
    style: _vm.blobTransform
  }, [_c('blob-selection-controls', {
    staticClass: "absolute-fill",
    style: {
      margin: `-${_vm.selectionControlsMargin}px`
    },
    attrs: {
      "blob": _vm.blob,
      "can-transform": _vm.canTransform,
      "is-primary": _vm.isPrimary,
      "is-cropping": _vm.isSelected && _vm.isCropping,
      "snap": _vm.snap,
      "mode": _vm.mode,
      "is-showing-virtual-keyboard": _vm.isShowingVirtualKeyboard
    },
    on: {
      "resize": _vm.resize,
      "rotate": _vm.rotate,
      "delete": function ($event) {
        return _vm.$emit('delete');
      }
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }