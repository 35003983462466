var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Occasions",
      "title": "Greeting cards / invitations for any occasion",
      "subtitle": "Add your own text, pictures, videos, and more",
      "action": "View all categories",
      "fill-width": "",
      "icon": _vm.mdiCards,
      "to": {
        name: 'catalog-categories'
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "root-grid"
  }, [_c('div', [_c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "li-pop font-weight-bold ec-dark-blue--text",
    attrs: {
      "exact": "",
      "to": {
        name: 'catalog'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiCards)
    }
  }), _c('div', [_vm._v("All cards / invitations")]), _c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1)], 1), _vm._l([0, 1, 2], function (seasonality) {
    return _vm.categories.filter(function (x) {
      return x.seasonality == seasonality;
    }).length ? _c('v-list', {
      staticClass: "transparent",
      class: {
        'li-grid': seasonality == 2
      },
      attrs: {
        "dense": ""
      }
    }, _vm._l(_vm.categories, function (c, i) {
      return c.seasonality == seasonality ? _c('v-list-item', {
        key: c.title,
        staticClass: "li-pop",
        class: {
          'font-weight-bold': seasonality != 2
        },
        style: {
          color: c.title_color || '#28547e'
        },
        attrs: {
          "exact": "",
          "to": {
            name: 'catalog-category',
            params: {
              category: c.name
            }
          }
        }
      }, [_c('v-icon', {
        class: {
          'preserve-color': seasonality == 1
        },
        style: {
          color: c.icon_color
        },
        attrs: {
          "small": ""
        },
        domProps: {
          "innerHTML": _vm._s(c.icon)
        }
      }), _c('div', {
        domProps: {
          "textContent": _vm._s(c.title)
        }
      }), _c('v-icon', {
        attrs: {
          "small": ""
        },
        domProps: {
          "innerHTML": _vm._s(_vm.mdiChevronRight)
        }
      })], 1) : _vm._e();
    }), 1) : _vm._e();
  }), _c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "li-pop font-weight-bold ec-dark-blue--text",
    attrs: {
      "exact": "",
      "to": {
        name: 'catalog-categories',
        hash: '#search'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiShape)
    }
  }), _c('div', [_vm._v("More categories…")]), _c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1)], 1)], 2), _c('div', {
    staticClass: "add-grid"
  }, [_c('div', [_c('div', {
    staticClass: "pt-3 px-3"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("Search for anything")]), _c('v-text-field', {
    staticClass: "mt-2 mb-1 white",
    attrs: {
      "label": "Search",
      "outlined": "",
      "dense": "",
      "rounded": "",
      "hide-details": "",
      "multiple": "",
      "single-line": "",
      "clearable": "",
      "append-icon": _vm.mdiMagnify
    },
    on: {
      "!click": function ($event) {
        $event.stopPropagation();
      },
      "click:append": _vm.search,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchText,
      callback: function ($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1), _c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, _vm._l(['flowers', 'funny dogs', 'animated', 'happy animals', 'summer party'], function (q) {
    return _c('v-list-item', {
      key: q,
      staticClass: "li-pop ec-dark-blue--text",
      attrs: {
        "exact": "",
        "to": {
          name: 'catalog-category',
          params: {
            category: 'all'
          },
          query: {
            q
          }
        }
      }
    }, [_c('div', {
      staticStyle: {
        "margin-right": "-12px"
      }
    }), _c('div', {
      domProps: {
        "textContent": _vm._s(q)
      }
    }), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1);
  }), 1)], 1), _c('div', [_c('div', {
    staticClass: "caption pt-3 px-3"
  }, [_vm._v("Design your own cards / invitations")]), _c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.DESIGN_YOUR_OWN, function (_ref) {
    var name = _ref[0],
      icon = _ref[1],
      route = _ref[2];
    return _c('v-list-item', {
      key: name,
      staticClass: "li-pop ec-dark-blue--text",
      attrs: {
        "exact": "",
        "to": Object.assign({
          name: 'catalog-category'
        }, route)
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _c('div', {
      domProps: {
        "textContent": _vm._s(name)
      }
    }), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1);
  }), 1)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }